<template>
  <Loader v-if="isLoading"/>
  <div>
    <Hero class="pt-5"/>
    <section
      class="container-fluid d-flex flex-column justify-content-center align-items-center py-5 shape-cross"
    >
      <div class="container mt-5">
        <div class="row d-flex justify-content-center py-6" id="nosotros">
          <div class="col-md-6 mb-lg-0 mb-3">
            <img
              src="@/assets/images/indoor.png"
              alt="Golden Garden"
              class="img-fluid col-lg-11 rounded-5 rounded-opacity"
            />
          </div>
          <div class="col-md-6 text-start">
            <div class="row d-flex justify-content-between flex-column h-100">
              <div>
                <h2 class="gradient-title">¿Quienes Somos?</h2>
                <p class="mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
                  iste sequi aut, deserunt, quia ipsam aperiam pariatur
                  consequuntur, eaque earum. <br />
                  <br />
                  Dolor a aliquam ducimus nemo animi nam dolore amet velit
                  assumenda consequatur est ex dicta odio, magnam reiciendis quod
                  tenetur error distinctio neque possimus consequuntur eveniet
                  sit. Accusamus?
                </p>
                <p class="mt-5">
                  ¿Te gustaría formar parte de nuestra comunidad?
                </p>
              </div>
              <div>
                <a href="#contacto" class="btn btn-primary px-3 py-2">
                  Inscripciones
                  <i class="fas fa-arrow-right ms-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="normativas-container py-3 shape-circle" id="normativas">
      <div class="container shape-circle" style="overflow: visible;">
        <div class="row">
          <!-- Columna izquierda - Título y descripción -->
          <div class="col-lg-5 mb-4 mb-lg-0">
            <h1 class="gradient-text-secondary fw-bold display-4 text-start">NORMATIVAS</h1>
            
            <p class="mt-4 text-start text-black-50 ">Informáte sobre el método para incorporarse a nuestra asociación y participar en nuestro cultivo comunitario. Es indispensable su registro en el REPROCANN como paso previo a su afiliación. Si surge cualquier pregunta o necesita aclaraciones, póngase en contacto con nosotros para brindarle la atención necesaria.
            </p>
          </div>
          
          <!-- Columna derecha - Acordeón -->
          <div class="col-lg-7">
            <div class="accordion" id="accordionNormativas">
              <!-- Ítem 1: Obtener tu REPROCANN -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <i class="fas fa-leaf me-2"></i> OBTENER TU REPROCANN
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionNormativas">
                  <div class="accordion-body">
                    En caso de que aún no estés inscripto como paciente con indicación médica, te proveeremos el contacto de un especialista de confianza de nuestra fundación, que te ayudará con el proceso.
                  </div>
                </div>
              </div>
              
              <!-- Ítem 2: Asociarte a nuestra institución -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <i class="fas fa-handshake me-2"></i> ASOCIARTE A NUESTRA INSTITUCIÓN
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionNormativas">
                  <div class="accordion-body">
                    Una vez que cuentes con el REPROCANN (o si ya estás registrado), deberás acercarte a la sede del club, con el documento original y con una fotocopia, así mismo también del DNI, para firmar el contrato y empezar a ser parte de nuestra hermosa y exclusiva comunidad.
                  </div>
                </div>
              </div>
              
              <!-- Ítem 3: Única condición excluyente -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <i class="fas fa-exclamation-circle me-2"></i> ÚNICA CONDICIÓN EXCLUYENTE
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionNormativas">
                  <div class="accordion-body">
                    <p><strong>TENÉS QUE SER UN SOCIO ACTIVO.</strong></p>
                    <p>Ser parte del CCBSAS implica un verdadero deseo de estar bien, de aprovechar este derecho medicinal y para eso es fundamental que mes a mes, cumplas con las condiciones mínimas que establecimos como norma para todos nosotros.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <section class="container-fluid bg-black py-6 shape-cross">
      <div class="container py-3 px-1">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-6 order-md-2">
            <div class="position-relative">
              <img
                src="@/assets/images/indoor_2.png"
                alt="Golden Garden Interior"
                class="img-fluid rounded-4 shadow-lg hover-scale"
              />
              <div class="position-absolute top-0 start-0 translate-middle">
                <div
                  class="bg-primary rounded-circle d-flex justify-content-center align-items-center experience-badge"
                >
                  <span class="text-white fw-bold">5+<br />años</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-md-1 mt-lg-0 mt-3">
            <div class="pe-md-5">
              <p class="text-start fw-bold fs-3" style="color: var(--primary);">
                +10 AÑOS
              </p>
              <h2 class="gradient-title fw-bold display-4 text-start mb-4">
                Nuestra Experiencia
              </h2>
              <p class="lead text-white-50 mb-4 text-start">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
                iste sequi aut, deserunt, quia ipsam aperiam pariatur
                consequuntur, eaque earum. <br />
                <br />
                Dolor a aliquam ducimus nemo animi nam dolore a
                sit. Accusamus?
              </p>

              <div class="d-flex align-items-center">
                <a href="#contacto" class="btn btn-primary btn-lg rounded-pill px-4">
                  Contáctanos
                  <i class="fas fa-chevron-right ms-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- faq section -->
    <section class="container-fluid bg-white py-6 shape-circle" id="faq">
      <div class="container py-3">
        <h2 class="gradient-text-secondary text-center display-4 fw-bold">
          Preguntas Frecuentes</h2>
        <p class="mb-4 text-black">
          A continuación, te presentamos algunas de las preguntas más frecuentes
          sobre Golden Garden. Si tienes alguna otra consulta, no dudes en
          contactarnos.
        </p>
        <div class="accordion" id="faqAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                ¿Qué es Golden Garden?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Golden Garden es una asociación civil argentina que se dedica al
                cultivo de cannabis con fines medicinales y científicos. Nuestro
                objetivo es brindar asistencia y apoyo a pacientes que requieran
                tratamientos con cannabis medicinal.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                ¿Cómo puedo asociarme?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                Para asociarte a Golden Garden, debes completar el formulario de
                inscripción en nuestro sitio web y abonar la cuota mensual. Una
                vez que tu solicitud sea aprobada, podrás acceder a todos los
                beneficios de nuestra asociación.
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              ¿Qué beneficios ofrece Golden Garden?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              Golden Garden brinda asistencia médica, estructura de cultivo, y
              acceso a productos de cannabis medicinal a sus asociados. Además,
              organizamos charlas, talleres y eventos relacionados con el cannabis
              y la salud.
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact Form Section -->
    <section id="contacto" class="container-fluid bg-black py-6 shape-cross">
      <div class="container shape-cross-2">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="gradient-title mb-4">Contactanos</h2>
            <p class="text-white mb-5">¿Tenés alguna consulta? Dejanos tu mensaje y te responderemos a la brevedad.</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <form class="contact-form" @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-6 mb-4">
                  <input type="text" class="form-control" placeholder="Nombre" required>
                </div>
                <div class="col-md-6 mb-4">
                  <input type="email" class="form-control" placeholder="Email" required>
                </div>
              </div>
              <div class="mb-4">
                <input type="text" class="form-control" placeholder="Asunto">
              </div>
              <div class="mb-4">
                <textarea class="form-control" rows="5" placeholder="Mensaje" required></textarea>
              </div>
              <button type="submit" class="btn btn-primary px-5" :disabled="isSubmitting">
                <span v-if="isSubmitting" class="spinner-border spinner-border-sm me-2" role="status"></span>
                {{ isSubmitting ? 'Enviando...' : 'Enviar Mensaje' }}
              </button>
            </form>
          </div>
        </div>
      </div>
      
      <!-- Toast Notification -->
      <div class="toast-container position-fixed bottom-0 end-0 p-3">
        <div class="toast align-items-center text-bg-success border-0" 
             role="alert" 
             aria-live="assertive" 
             aria-atomic="true"
             ref="toast">
          <div class="d-flex">
            <div class="toast-body p-3">
              ¡Mensaje enviado exitosamente!
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"></button>
          </div>
        </div>
      </div>
    </section>

    <!-- Map Section -->
    <section class="container-fluid bg-light py-6 shape-cross">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center mb-5">
            <h2 class="gradient-text-secondary">
              <i class="fas fa-map-marker-alt me-2"></i>
              Ubicación</h2>
            <p class="text-black-50">Encontranos en Moreno, Buenos Aires, Argentina.</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div id="map" class="map-container"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="footer bg-black py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 mb-4 mb-lg-0 d-flex flex-column align-items-lg-start align-items-center">
              <img src="@/assets/images/logo_color.png" alt="Golden Garden" class="img-fluid mb-3" width="150">
            <h5 class="text-white mb-4 text-lg-start text-center">Golden Garden</h5>
            <p class="footer-text text-lg-start text-center col-lg-8">Cultivando bienestar para nuestra comunidad desde hace más de 5 años.</p>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0 px-lg-5">
            <h5 class="text-white mb-4 text-lg-start text-center">Enlaces Rápidos</h5>
            <ul class="footer-links text-lg-start text-center">
              <li><a href="#">Inicio</a></li>
              <li><a href="#">Sobre Nosotros</a></li>
              <li><a href="#">Inscripciones</a></li>
              <li><a href="#">Contacto</a></li>
            </ul>
          </div>
          <div class="col-lg-4">
            <h5 class="text-white mb-4 text-lg-start text-center">Síguenos</h5>
            <div class="social-links d-flex justify-content-lg-start justify-content-center">
              <a href="#" class="me-3"><i class="fab fa-instagram"></i></a>
              <a href="#" class="me-3"><i class="fab fa-facebook"></i></a>
              <a href="#" class="me-3"><i class="fab fa-whatsapp"></i></a>
            </div>
          </div>
        </div>
        <hr class="footer-divider">
        <div class="row">
          <div class="col-12 text-center">
            <p class="footer-copyright">© 2025 Golden Garden. Todos los derechos reservados.</p>
          </div>
          <div>
              <p class="footer-copyright">Desarrollado por <a href="https://www.permaculture.ar" target="_blank" class="text-white">
                  <img src="@/assets/images/logo-star-white.svg" alt="Permaculture logo" class="img-fluid" style="margin-top: 11px;" width="130">
              </a></p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Loader from "@/components/Loader.vue";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Toast } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
  name: "HomeView",
  components: {
    Hero,
    Loader
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      toast: null
    };
  },
  methods: {
    async handleSubmit(event) {
      this.isSubmitting = true;
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Show success toast
      this.toast.show();
      
      this.isSubmitting = false;
      
      // Reset form
      event.target.reset();
    },
    scrollToTarget(hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  mounted() {
    // Ocultar el loader después de 1.5 segundos
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);

    // Check for hash in URL on page load
    if (window.location.hash) {
      this.$nextTick(() => {
        this.scrollToTarget(window.location.hash);
      });
    }

    // Add event listener for hash changes
    window.addEventListener('hashchange', () => {
      this.scrollToTarget(window.location.hash);
    });

    // Fix for marker icon
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    // Initialize map
    const map = L.map('map').setView([-34.63768269096673, -58.7388430328081], 15);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    // Custom marker icon
    const customIcon = L.divIcon({
      className: 'custom-map-marker',
      html: '<i class="fas fa-map-marker-alt"></i>',
      iconSize: [40, 40],
      iconAnchor: [20, 40]
    });

    // Add marker with custom icon
    L.marker([-34.63768269096673, -58.7388430328081], { icon: customIcon }).addTo(map)
      .bindPopup('Golden Garden')
      .openPopup();

    // Initialize toast
    this.toast = new Toast(this.$refs.toast, {
      delay: 3000
    });
  }
};
</script>

<style>
.rounded-opacity {
  position: relative;
}
.rounded-opacity::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
}
.py-6 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.hover-scale {
  transition: transform 0.3s ease;
}
.hover-scale:hover {
  transform: scale(1.02);
}
.experience-badge {
  width: 100px;
  height: 100px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .experience-badge {
    width: 80px;
    height: 80px;
    padding: 0.8rem;
    left: 200px !important; /* Override the negative translate */
    top: 200px !important;
    transform: none !important;
    display: none !important;
  }
}
.lead {
  font-size: 1.1rem;
  line-height: 1.7;
}
/* faq styles */
.accordion-item{
    border:none;
    background-color: var(--bg-primary);
    margin:10px 0;
}
.accordion-button {
  background-color: var(--bg-tertiary);
  color: var(--text-secondary);
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  text-align: left;
}
.accordion-button:hover {
  background-color: var(--primary);
  color: var(--bg-primary);
}
.accordion-button:not(.collapsed) {
  background-color: var(--primary-dark);
  color: var(--bg-primary);
}
.accordion-body {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 1rem 1.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  text-align: left;
}
.accordion-button::after {
  filter: invert(1);
}
.accordion-button:not(.collapsed)::after {
  filter: invert(0);
}
.normativas-container {
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.titulo-normativas {
  font-weight: 700;
  color: var(--primary);
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 0;
}

.subtitulo-normativas {
  font-size: 1rem;
  color: #6c757d;
  letter-spacing: 2px;
  margin-top: 10px;
}

.link-reprocann {
  color: var(--primary);
  text-decoration: underline;
  font-weight: 500;
}

.link-reprocann:hover {
  color: var(--primary-dark);
}

.accordion-item {
  border: none;
  margin-bottom: 1rem;
  border-radius: 0.5rem !important;
  overflow: hidden;
}



.accordion-body {
  padding: 1.5rem;
}

@media (max-width: 992px) {
  .titulo-normativas {
    font-size: 3.5rem;
  }
}

/* Contact Form Styles */
.form-control {
  background-color: var(--bg-tertiary);
  border: 1px solid var(--bg-tertiary);
  color: white;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.form-control:focus {
  background-color: var(--bg-tertiary);
  border-color: var(--primary);
  color: white;
  box-shadow: none;
}

.form-control::placeholder {
  color: var(--text-light);
  opacity: 0.8;
}

/* Footer Styles */
.footer {
  background-color: var(--bg-secondary);
  color: var (--text-secondary);
}

.footer-text {
  color: var(--text-light);
  line-height: 1.6;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: var(--text-light);
  text-decoration: none;
  transition: color 0.3s;
}


.social-links a {
  color: var (--text-light);
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-links a:hover {
  color: var(--primary);
}

.footer-divider {
  border-color: var(--bg-tertiary);
  margin: 2rem 0;
}

.footer-copyright {
  color: var (--text-light);
  font-size: 0.9rem;
}
.footer-copyright:hover{
  opacity: .7;
  transition: all .3s ease;
}

/* Map Styles */
.map-container {
  height: 400px;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .map-container {
    height: 300px;
  }
  h2{
    font-size: 2rem !important;
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Ajusta este valor según el alto de tu navbar */
}

/* Decorative Shapes */
.shape-circle {
  position: relative;
  overflow: hidden;
}

.shape-circle::before,
.shape-circle::after {
  content: '';
  position: absolute;
  border: 3px solid var(--primary);
  border-radius: 50%;
  opacity: 0.7;
  z-index: 0;
}

.shape-circle::before {
  width: 50px;
  height: 50px;
  right: 5%;
  bottom: 10%;
}

.shape-circle::after {
  width: 50px;
  height: 50px;
  left: -2%;
  top: 15%;
}

.shape-circle-2 {
  position: relative;
  overflow: hidden;
}

.shape-circle-2::before,
.shape-circle-2::after {
  content: '';
  position: absolute;
  border: 3px solid var(--primary);
  border-radius: 50%;
  opacity: 0.7;
  z-index: 0;
}

.shape-circle-2::before {
  width: 50px;
  height: 50px;
  right: 5%;
  bottom: 10%;
}

.shape-circle-2::after {
  width: 50px;
  height: 50px;
  left: -2%;
  bottom: 15%;
}

.shape-cross {
  position: relative;
  overflow: hidden;
}

.shape-cross::before,
.shape-cross::after {
  content: '+';
  position: absolute;
  font-size: 60px;
  font-weight: 300;
  color: var(--primary);
  opacity: 1;
  z-index: 0;
}

.shape-cross::before {
  right: 10%;
  top: 15%;
}

.shape-cross::after {
  left: 5%;
  bottom: 10%;
}
.shape-cross-2{
  position: relative;
  overflow: hidden;
}

.shape-cross-2::before,
.shape-cross-2::after {
  content: '+';
  position: absolute;
  font-size: 60px;
  font-weight: 300;
  color: var(--primary);
  opacity: 1;
  z-index: 0;
}

.shape-cross-2::before {
  right: 10%;
  bottom: 15%;
}

.shape-cross-2::after {
  left: 5%;
  top: 10%;
}


@media (max-width: 768px) {
  .shape-circle {
   display: none;
  }
  
  .shape-cross::before,
  .shape-cross::after {
    font-size: 30px;
  }
}

.custom-map-marker {
  color: var(--primary);
  font-size: 40px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.toast-container {
  z-index: 9999;
}
</style>
